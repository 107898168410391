import '../css/app.css';
import '../css/media.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import * as Sentry from "@sentry/vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'BusinessCertificateServices.com';

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, app, props, plugin }) {
        const vueApp = createApp({ render: () => h(app, props) });

        // Initialize Sentry
        Sentry.init({
            app: vueApp,
            dsn: "https://dbfd8dd4eb3e8c45c413f2b0549b76a0@o4507189048901632.ingest.us.sentry.io/4507189050802176",
            integrations: [
                Sentry.replayIntegration(),
            ],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        return vueApp
          .use(plugin)
          .use(ZiggyVue, Ziggy)
          .mixin({ methods: { route }})
          .mount(el);
    }
});

InertiaProgress.init({ color: '#4B5563' });